<template>
  <el-dialog v-model="dialogVisible" :title="dialogVisibletitle" :close-on-click-modal="false" width="560px">

    <template v-if="dialogVisibletitle === '发票抬头'">
      <div class="dp-f">
        <el-form ref="ruleFormRef" :model="ruleForm" :rules="rules" status-icon label-width="100px"
          class="demo-ruleForm w100" label-position="left">
          <!-- <el-form-item label="开具类型：" prop='invoiceHeadType'>
            <el-radio-group v-model="ruleForm.invoiceHeadType" disabled>
              <el-radio label="单位">单位</el-radio>
            </el-radio-group>
          </el-form-item> -->
          <el-form-item label="开具类型：" prop='invoiceType'>
            <el-radio-group v-model="ruleForm.invoiceType">
              <el-radio :label=0>增值税专用发票</el-radio>
              <el-radio :label=1>增值税普通发票</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="发票抬头：" prop='invoiceHeader'>
            <el-input v-model="ruleForm.invoiceHeader" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="请输入发票抬头" />
          </el-form-item>
          <el-form-item label="税号：" prop='invoiceParagraph' class="is-required">
            <el-input v-model="ruleForm.invoiceParagraph" :maxlength="config.inputMaxlength" show-word-limit
              placeholder="请输入税号" />
          </el-form-item>
          <template v-if="ruleForm.invoiceType == 0">
            <el-form-item label="开户银行：" prop='bank' class="is-required">
              <el-input v-model="ruleForm.bank" :maxlength="config.inputMaxlength" show-word-limit
                placeholder="请输入开户银行" />
            </el-form-item>
            <el-form-item label="开户账号：" prop='bankNumber' class="is-required">
              <el-input v-model="ruleForm.bankNumber" :maxlength="config.inputMaxlength" show-word-limit
                placeholder="请输入开户账号" />
            </el-form-item>
            <el-form-item label="注册地址：" prop='address' class="is-required">
              <el-input v-model="ruleForm.address" :maxlength="config.inputMaxlength" show-word-limit
                placeholder="请输入注册地址" />
            </el-form-item>
            <el-form-item label="固定电话：" prop='fixedTelephone' class="is-required">
              <el-input v-model="ruleForm.fixedTelephone" :maxlength="config.inputMaxlength" show-word-limit
                placeholder="请输入固定电话" />
            </el-form-item>
          </template>
        </el-form>
      </div>
    </template>
    <template #footer>
      <span class="dialog-footer dp-f fd-rr">
        <oabutton class="searcML" width='120' height='40' title="确定" CSStype=2 @buttonclick="submitForm()"
          v-model:loading="dialogloading"></oabutton>
        <oabutton class="searcML" width='120' height='40' title="取消" CSStype=3 @buttonclick="dialogVisible = false">
        </oabutton>
      </span>
    </template>
  </el-dialog>
</template>
<script setup>
import { reactive, ref, unref, nextTick, defineEmits } from 'vue'
import { objdata_ret, objdata_set } from "@/utils/server/format";
import { handleMessage } from "@/utils/server/confirm";
import { validLandline,validPhone, validSocialCode } from "@/utils/server/validate.js";
import { httpToken } from "@/utils/request";
import qs from "qs";
const dialogloading = ref(false);// 弹框按钮loading 显示
const dialogVisible = ref(false)//弹框显示
const dialogVisibletitle = ref(false)//弹框标题
const emit = defineEmits(['Emit'])
const id = ref(0);//操作的id
const ruleFormRef = ref(null);//表单ref
const ruleForm = reactive({//from 表单数据
  // invoiceHeadType: '单位',//抬头类型
  invoiceParagraph: '',//税号
  invoiceHeader: '',//发票抬头
  invoiceType: '',//发票类型
  bank: '',//开户银行
  bankNumber: '',//开户账号
  address: '',//注册地址
  fixedTelephone: '',//固定电话 0532-88901158
})
// 校验手机号格式
const validLandlineData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入固定电话'))
  } else {
    if (validLandline(value)) {
      callback()
    } else if (validPhone(value)) {//增加普通手机号校验
      callback()
    } else
      callback(new Error('固定电话格式错误'))
  }
}
// 校验统一社会信用代码
const validSocialCodeData = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入税号'))
  } else {
    if (validSocialCode(value)) {
      callback()
    } else
      callback(new Error('税号格式错误'))
  }
}
const rules = reactive({//from 表单校验
  invoiceParagraph: [
    { validator: validSocialCodeData, trigger: 'blur' },
  ],
  invoiceHeader: [
    { required: true, message: '请输入发票抬头', trigger: 'blur' },
  ],
  bank: [
    { required: true, message: '请输入开户银行', trigger: 'blur' },
  ],
  bankNumber: [
    { required: true, message: '请输入开户账号', trigger: 'blur' },
  ],
  address: [
    { required: true, message: '请输入注册地址', trigger: 'blur' },
  ],
  fixedTelephone: [
    { validator: validLandlineData, trigger: 'blur' }
  ],
})
// 表单重置
const fromDataReset = (() => {
  objdata_ret(ruleForm)
  // ruleForm.invoiceHeadType = '单位'
  ruleForm.invoiceType = 0
})
const editclick = ((row) => {
  fromDataReset()
  objdata_set(ruleForm, row)
  dialogloading.value = false
  dialogVisibletitle.value = '发票抬头'
  dialogVisible.value = true
  nextTick(() => {
    unref(ruleFormRef).clearValidate()//清除表单验证结果
  })
})
// 表单提交
const submitForm = async () => {
  dialogloading.value = true
  const form = unref(ruleFormRef)
  if (!form) {
    dialogloading.value = false
    return
  }
  await form.validate((valid) => {
    if (valid) {
      emit('Emit', ruleForm)
      dialogloading.value = false
      dialogVisible.value = false
    } else {
      dialogloading.value = false
    }
  })
}
defineExpose({
  editclick,
});
</script>